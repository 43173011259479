<template>
  <users-add></users-add>
</template>

<script>
import UsersAdd from "@/components/admin/users/usersAdd";
export default {
  name: "usersAddView",
  components: { UsersAdd },
};
</script>

<style scoped></style>
